import { useStaticQuery, graphql } from "gatsby";
import { rgba } from "polished";
import { Wrapper, Container, Row } from "../../../../src/components/ui/Grid";
import Article, { Separator } from "../../../../src/components/ui/Article";
import theme from "../../../../src/theme";
import Values from "../../../../src/components/sections/Values";
import TOC from "../../../../src/components/sections/TableOfContents";
import Sticky from "react-stickynode";
import Super from "../../../../src/components/ui/Super";
import * as React from 'react';
export default {
  useStaticQuery,
  graphql,
  rgba,
  Wrapper,
  Container,
  Row,
  Article,
  Separator,
  theme,
  Values,
  TOC,
  Sticky,
  Super,
  React
};