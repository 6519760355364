import { linearGradient } from "polished";
import Seo from "../../../../src/components/seo";
import { Wrapper, Container, Row, Column } from "../../../../src/components/ui/Grid";
import PageIntro from "../../../../src/components/PageIntro";
import Article, { Box, UnderlineWhite, ColumnSpacing, DarkBgIllustration, MarkdownStyle } from "../../../../src/components/ui/Article";
import { Underline } from "../../../../src/components/ui/Heading";
import theme, { devices } from "../../../../src/theme";
import about from "../../../../src/images/about.svg";
import * as React from 'react';
export default {
  linearGradient,
  Seo,
  Wrapper,
  Container,
  Row,
  Column,
  PageIntro,
  Article,
  Box,
  UnderlineWhite,
  ColumnSpacing,
  DarkBgIllustration,
  MarkdownStyle,
  Underline,
  theme,
  devices,
  about,
  React
};